//.DataContext.js
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

export const DataContext = createContext();

export const useData = () => {
   return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
   const [loading, setLoading] = useState(true);
   
   const [topWords, setTopWords] = useState(null);
   const [topWordsEtymology, setTopWordsEtymology] = useState(null);
   const [genzBible, setGenzBible] = useState(null);
   const [kjvBible, setKjvBible] = useState(null);
   const [bibleCharacters, setBibleCharacters] = useState(null);
   const [redLetters, setRedLetters] = useState(null);
   const [genZAudioChapters, setGenZAudioChapters] = useState(null);
   
   useEffect(() => {
      Promise.all([
         import('../../assets/en_genz_bible.json'),
         import('../../assets/en_kjv_bible.json'),
         import('../../assets/en_kjv_top_words.json'),
         import('../../assets/en_kjv_top_words_etymology.json'),
         import('../../assets/en_kjv_characters.json'),
         import('../../assets/en_kjv_red_letter.json'),
         import('../../assets/en_genz_audio_chapters.json'),
      ])
         .then(([file1, file2 ,file3, file4, file5, file6, file7]) => {
            setGenzBible(file1.default);
            setKjvBible(file2.default);
            setTopWords(file3.default);
            setTopWordsEtymology(file4.default);
            setBibleCharacters(file5.default);
            setRedLetters(file6.default);
            setGenZAudioChapters(file7.default);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);
   
   const memoizedTopWords = useMemo(() => topWords?.top_words, [topWords]);
   const memoizedTopWordsEtymology = useMemo(() => topWordsEtymology?.top_words_etymology, [topWordsEtymology]);
   const memoizedGenzBible = useMemo(() => genzBible, [genzBible]);
   const memoizedKjvBible = useMemo(() => kjvBible, [kjvBible]);
   const memoizedBibleCharacters = useMemo(() => bibleCharacters, [bibleCharacters]);
   const memoizedRedLetters = useMemo(() => redLetters?.red_letters, [redLetters]);
   const memoizedGenZAudioChapters = useMemo(() => genZAudioChapters?.audio_chapters, [genZAudioChapters]);
   
   return (
      <DataContext.Provider value={{
         memoizedTopWords,
         memoizedTopWordsEtymology,
         memoizedGenzBible,
         memoizedKjvBible,
         memoizedBibleCharacters,
         memoizedRedLetters,
         memoizedGenZAudioChapters,
         loading }}
      >
         {children}
      </DataContext.Provider>
   );
};
