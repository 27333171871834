import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import useResult from "../hooks/useResult";
import useBibleGPT from "../hooks/useBibleGPT";
import {getUrlParam} from "../constants/helpers";
import {CLIENT_HOST} from "../constants/constants";
import {ThemeContext} from "./ThemeContext";
import ReactGA from "react-ga";
import {useLocation} from "react-router-dom";
import {useMediaQuery, useTheme} from "@material-ui/core";
import useBibleGPTCommentary from "../hooks/useBibleGPTCommentary";
import useBibleGPTWordCommentary from "../hooks/useBibleGPTWordCommentary";
import useBibleGPTModernCommentary from "../hooks/useBibleGPTModernCommentary";
import { useNavigate } from 'react-router-dom';


export const getBookAbbrevAndChapterNumber = (pathname) => {
  const pathParts = decodeURI(pathname).split("/").filter(Boolean);
  const lastPathPart = pathParts[pathParts.length - 1];
  const isLastPartChapter = !isNaN(lastPathPart);
  let latestBookAbbrev = pathParts[0];
  let latestChapterNumber = isLastPartChapter ? parseInt(lastPathPart, 10) : null;
  if (pathParts.length > 1 && !isLastPartChapter) {
    latestBookAbbrev = `${pathParts[0]} ${pathParts[1]}`;
    latestChapterNumber = pathParts.length > 2 ? parseInt(pathParts[2], 10) : null;
  }
  return { latestBookAbbrev, latestChapterNumber };
}
export const SearchContext = createContext({});
export const SearchContextProvider = ({ children, props }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchSM = useMediaQuery(theme.breakpoints.down('xs'));
  const [bookAbbrev, setBookAbbrev] = useState( '')
  const [chapterNumber, setChapterNumber] = useState( '')
  const [searchOpen, setSearchOpen] = useState( false)
  const [menuOpen, setMenuOpen] = useState( false)
  const toggleMenu = useCallback(()=>setMenuOpen(!menuOpen),[menuOpen])
  
  const [query, setQuery] = useState( '')
  const [debounceQuery, setDebounceQuery] = useState('');
  const location = useLocation();
  
  const toggleSearch = useCallback(()=>{
    setSearchOpen(!searchOpen)
  },[searchOpen])
  
  
  useEffect(()=>{
    const { latestBookAbbrev, latestChapterNumber } = getBookAbbrevAndChapterNumber(location.pathname);
    setBookAbbrev(latestBookAbbrev)
    setChapterNumber(latestChapterNumber)
    //console.log(latestBookAbbrev,latestChapterNumber)
  },[location.pathname])
  
  
  useEffect(() => {
    if (!window.location.hostname.includes("localhost")) { /* Production Only */
      const adjustedURL = directLink.split(/\.ai\/?|\.dev\/?|\.bible\/?/);
      //ReactGA.initialize('UA-154880-13');
      //ReactGA.pageview(adjustedURL[1]);
    }
  },[location.pathname])
  
  const originalPageTitle = useMemo(()=>document.title,[])
  
  useEffect(() => {
    if(getUrlParam('q')){
      setQuery(getUrlParam('q'))
      setSearchOpen(true)
    }
  }, []);
  
  useEffect(() => {
    const newUrl = new URL(window.location.href);
    if (debounceQuery) {
      newUrl.searchParams.set("q", debounceQuery);
      setSearchOpen(true)
    } else {
      newUrl.searchParams.delete("q");
    }
    window.history.pushState({}, "", newUrl);
  }, [debounceQuery]);
  
  
  useEffect(() => {
    let timeOutId;
    
    if (query !== debounceQuery) {
      timeOutId = setTimeout(() => {
        setDebounceQuery(query);
        document.title = debounceQuery ? debounceQuery + " - GenZ.Bible" : originalPageTitle; // Assume originalPageTitle is defined
      }, 1000);
    }
    
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [query, debounceQuery]);
  
  const directLink = useMemo(()=>{
      return `${CLIENT_HOST}/?q=${debounceQuery}`
  },[debounceQuery])
  
  const forceSearch = useCallback(value => {
    setDebounceQuery(query);
  },[query]);
  
  
  
  const { data: bibleGPTData, isLoading: bibleGPTLoading } = useBibleGPT(bookAbbrev, chapterNumber);
  // todo - activvate - const { data: bibleGPTData, isLoading: bibleGPTLoading } = useBibleGPTWhoWhatWhenWhereWhy(bookAbbrev, chapterNumber);
  const { data: bibleGPTCommentaryData, isLoading: bibleGPTCommentaryLoading } = useBibleGPTCommentary(bookAbbrev, chapterNumber);
  const { data: bibleGPTModernCommentaryData, isLoading: bibleGPTModernCommentaryLoading } = useBibleGPTModernCommentary(bookAbbrev, chapterNumber);
  const { data: bibleGPTWordCommentaryData, isLoading: bibleGPTWordCommentaryLoading } = useBibleGPTWordCommentary(debounceQuery);
  
  //console.log(debounceQuery)
  //nothing else below?
  
  
  
  
  const [companyCountryPhone, setCompanyCountryPhone] = useState('+1');
  
  
  const [page, setPage] = useState( 0);
  const [rowsPerPage, setRowsPerPage] = useState( 10);
  const [type, setType] = useState( '');
  const [locale, setLocale] = useState('en')
  const [filterExplicit, setFilterExplicit] = useState( true);
  const [timeRange, setTimeRange] = useState( 'any')
  const oneMonthAgo = useMemo(()=>{
       let d = new Date();
       d.setMonth(d.getMonth() - 1);
       return d.toISOString().substring(0,10);
  }
  ,[]);
  const [timeRangeCustomStart, setTimeRangeCustomStart] = useState(oneMonthAgo)
  const [timeRangeCustomEnd, setTimeRangeCustomEnd] = useState(new Date().toISOString().substring(0,10))
  const [indexCount, setIndexCount] = useState(0)
  const [entityCount, setEntityCount] = useState(0)
  const [trendingList, setTrendingList] = useState([])
  const [searchResults, setSearchResults] = useState(null)
  const [totalSearchResults, setTotalSearchResults] = useState(-1)
  const [showExtendedCategories, setShowExtendedCategories] = useState( false)
  
  //onload, over-ride defaults
  
  
  const trendData = []
  const trendsLoading = false
  //const { data: trendData, isLoading: trendsLoading } = useTrends();
  const { data: searchResultsObj, isLoading: resultsLoading } = useResult('', companyCountryPhone); // debounceQuery
  
  useEffect(() => {
   if(searchResultsObj){
     setSearchResults(searchResultsObj)
   }
  }, [searchResultsObj, resultsLoading]);
  
  useEffect(() => {
   if(trendData){
     setIndexCount(trendData.indexCount)
     setEntityCount(trendData.entityCount)
     setTrendingList(trendData.data)
   }
  }, [trendData]);
  
  return (
    <SearchContext.Provider
      value={{
        actions: {
          setQuery,
          setDebounceQuery,
          setTimeRange,
          setLocale,
          setFilterExplicit,
          forceSearch,
          setTrendingList,
          setPage,
          setType,
          setRowsPerPage,
          setTimeRangeCustomStart,
          setTimeRangeCustomEnd,
          setShowExtendedCategories,
          setCompanyCountryPhone,
          
          setMenuOpen,
          toggleMenu,
          toggleSearch,
          navigate
        },
        state: {
          query,
          debounceQuery,
          timeRange,
          locale,
          filterExplicit,
          timeRangeCustomStart,
          timeRangeCustomEnd,
          trendingList,
          searchResults,
          totalSearchResults,
          resultsLoading,
          trendsLoading,
          indexCount,
          entityCount,
          page,
          type,
          rowsPerPage,
          directLink,
          showExtendedCategories,
          companyCountryPhone,
          bibleGPTData,
          bibleGPTLoading,
          bibleGPTCommentaryLoading,
          bibleGPTWordCommentaryLoading,
          bibleGPTCommentaryData,
          bibleGPTWordCommentaryData,
          bibleGPTModernCommentaryLoading,
          bibleGPTModernCommentaryData,
          chapterNumber,
          bookAbbrev,
          matchSM,
          menuOpen,
          searchOpen,
        },
      }}
      {...props}
    >
      {children}
    </SearchContext.Provider>
  );
};
