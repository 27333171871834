import React, {useContext, useState} from "react";
import {Route, Link, Routes} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
   Typography,
   Drawer,
   List,
   ListItem,
   ListItemText,
   Button,
   Card,
   CardContent,
   Grid,
   CircularProgress,
   ListItemAvatar,
   Avatar,
   IconButton, Box,
} from "@material-ui/core";
import {getNameFromAbbreviation} from "../../lib/constants/helpers";
import Logo from "../../assets/logo.svg";
import {SearchContext, useData} from "../../lib/contexts";
import EventsByLocation from "./EventsByLocation";
import {Menu} from "@material-ui/icons";
import Commentary from "./Commentary";
import WordCloud from "../WordCloud/WordCloud";
import BibleVerseCountChart from "../BookCharts/BibleVerseCountChart";
import BibleEventsByLocation from "../BookCharts/BibleEventsByLocation";
import BibleCharacterNetworkGraph from "../BookCharts/BibleCharacterNetworkGraph";
import BibleEventList from "../BookCharts/BibleEventList";
import ModernCommentary from "./ModernCommentary";
import SearchFormBox from '../SearchFormBox';
import PlayAudioChapter from '../PlayAudioChapter';

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
   },
   appBar: {
      zIndex: theme.zIndex.drawer + 1,
   },
   drawer: {
      width: 380,
      flexShrink: 0,
   },
   drawerPaper: {
      width: 380,
   },
   drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
   },
   content: {
      flexGrow: 1,
      padding: theme.spacing(3),
   },
   title: {
      flexGrow: 1,
   },
}));

const RenderBook = () => {
   const { state: {matchSM, menuOpen, bookAbbrev}, actions: {toggleMenu} } = useContext(SearchContext);
   const { memoizedKjvBible } = useData() ?? {};
   
   const booksListItems = memoizedKjvBible.map((book,index) => (
      <ListItem button key={book.abbrev} component={Link} to={`/${getNameFromAbbreviation(book.abbrev)}`}>
         <ListItemAvatar key={book.abbrev}>
            <Avatar style={{width:40,height:40}}>{index+1}</Avatar>
         </ListItemAvatar>
         <ListItemText primary={<>{getNameFromAbbreviation(book.abbrev)}</>}/>
      </ListItem>
   ));
   
   return (
      <>
         <Drawer variant={matchSM ? "temporary" : "permanent"} anchor="left" open={menuOpen} onClose={toggleMenu}>
            <a href={'../../'} style={{display:'block',margin:'10px auto auto',width:100}}>
               <img src={Logo} style={{margin:'10px auto auto',width:'100%'}}/>
            </a>
            {/*<DarkModeToggle/>*/}
            <List style={{minWidth:250, width:'100%', overflowY:'auto'}}>{booksListItems}</List>
            <br/>
            {!window.location.href.includes('localhost') && <stripe-buy-button
               style={{marginBottom: -5, minWidth: 250, width: '100%', maxWidth: 350}}
               buy-button-id="buy_btn_1MyPrTJNj13cAQxS82WKUt3B"
               publishable-key="pk_live_51MyPbeJNj13cAQxSvscabauhA5XMMKRinqAuZ9sG2Trki2P6mbIXdWroLC61dkD0Y9ExCbQIqyIiF79HfmKVA8Is00cXvG9I8K"
            >
            </stripe-buy-button>}
         </Drawer>
         <main style={{marginLeft: bookAbbrev ? matchSM ? 20 : 310 : matchSM ? 20 : 270, marginRight:20}}>
            {bookAbbrev ? <BookContent/> : <HomePage/>}
         </main>
      </>
   );
};

const BookContent = () => {
   const { state: {matchSM, matchMD, bookAbbrev} } = useContext(SearchContext);
   const { memoizedKjvBible } = useData() ?? {};
   
   const book = memoizedKjvBible.find((b) => getNameFromAbbreviation(b.abbrev) === bookAbbrev);
   
   if (!book) {
      return <Typography variant="body1">Book not found</Typography>;
   }
   
   const chapters = book.chapters.map((chapter, index) => (
      <Button key={index} component={Link} to={`/${getNameFromAbbreviation(book.abbrev)}/${index + 1}`}>
         Chapter {index + 1}
      </Button>
   ));
   
   return (
      <>
         {matchSM && <Header/>}
         <Grid container item xs={12} spacing={0} justifyContent={"space-between"}>
            <Grid item xs={12} style={{ order: matchSM ? 1 : 1 }}>
               <SearchFormBox/>
            </Grid>
         </Grid>
         <Card>
         <CardContent style={{maxHeight: matchSM ? 200 : matchMD ? 270 : 320, overflowY:'auto', paddingBottom:10}}>
         <Typography variant="h4">{getNameFromAbbreviation(book.abbrev)}</Typography>
         <div>{chapters}</div>
         </CardContent>
         </Card>
         <Routes>
            <Route path=":chapterNumber" element={<PageLayout/>} />
         </Routes>
      </>
   );
};
const ChapterContent = ({genzMode, kjvMode}) => {
   const { state: {bookAbbrev, chapterNumber}, actions: { setQuery} } = useContext(SearchContext);
   const { memoizedTopWords, memoizedGenzBible, memoizedKjvBible, memoizedRedLetters, memoizedGenZAudioChapters } = useData() ?? {};
   const chosenBook = genzMode ? memoizedGenzBible : memoizedKjvBible
   
   const book = chosenBook.find((b) => getNameFromAbbreviation(b.abbrev) === bookAbbrev);
   
   if (!book) {
      return <Typography variant="body1">Book not found</Typography>;
   }
   
   const chapter = book.chapters[chapterNumber - 1];
   //console.log('chapter ', chapterNumber, chapter)
   // Check if memoizedGenZAudioChapters contains the audio URLs for the chosen book
   const audioBookAvailable = memoizedGenZAudioChapters.hasOwnProperty(book.abbrev);

// If it's available, get the audio URL for the particular chapter
   const chosenAudioChapter = audioBookAvailable ? memoizedGenZAudioChapters[book.abbrev][chapterNumber] : null;
   
   
   if (!chapter) {
      return <Typography variant="body1">Chapter not found</Typography>;
   }
   
   const topWordSet = new Set(memoizedTopWords.map((wordObj) => wordObj.word.toLowerCase()));
   
/*
  const verses = chapter.map((verse, index) => {
      const words = verse.split(' ').map((word, wordIndex) => {
         const cleanWord = word.replace(/[.,;:'"?!()]/g, '').toLowerCase(); // remove punctuation and convert to lowercase
         const originalWord = word.match(/[a-zA-Z0-9]+/)?.[0] ?? null; // extract the original word without punctuation
         if (topWordSet.has(cleanWord)) {
            return (
               <React.Fragment key={`${index}-${wordIndex}`}>
                  <a
                     href="#"
                     onClick={(e) => {
                        e.preventDefault();
                        setQuery(cleanWord);
                     }}
                  >{originalWord}</a>{word.slice(originalWord.length)}{' '}
               </React.Fragment>
            );
         }
         return word + ' ';
      });
      return (
         <React.Fragment key={index}>
            <h3 style={{display:'inline'}}>{index + 1}. </h3>
            <Typography key={index} variant="body1" style={{display:'inline'}}>
               {words}
            </Typography>
            <br/>
         </React.Fragment>
      );
   });
*/
   const verses = chapter.map((verse, index) => {
      const redLetterInfo = memoizedRedLetters[book?.abbrev]?.[chapterNumber]?.[index + 1]; // Assuming chapterNumber and abbrev are defined
      let charIndex = 0;
      
      const words = verse.split(' ').map((word, wordIndex) => {
         const cleanWord = word.replace(/[.,;:'"?!()]/g, '').toLowerCase(); // remove punctuation and convert to lowercase
         const originalWord = word.match(/[a-zA-Z0-9]+/)?.[0] ?? null; // extract the original word without punctuation
         
         const isRedLetter = !genzMode && redLetterInfo && (charIndex >= redLetterInfo[0] && charIndex <= redLetterInfo[1]);
         
         charIndex += word.length + 1; // update charIndex for the next word
         
         if (topWordSet.has(cleanWord)) {
            return (
               <React.Fragment key={`${index}-${wordIndex}`}>
                  <a
                     href="#"
                     onClick={(e) => {
                        e.preventDefault();
                        setQuery(cleanWord);
                     }}
                     style={isRedLetter ? {color: '#FFAE92', fontWeight:'bold',textDecoration:'none',borderBottom:'1px solid #fff'} : {}}
                  >
                     {originalWord}
                  </a>
                  {word.slice(originalWord.length)}{' '}
               </React.Fragment>
            );
         }
         
         return (
            <span style={isRedLetter ? {color: '#FFAE92', fontWeight:'bold'} : {}}>
        {word + ' '}
      </span>
         );
      });
      
      return (
         <React.Fragment key={index}>
            <h3 style={{display: 'inline'}}>{index + 1}. </h3>
            <Typography key={index} variant="body1" style={{display: 'inline'}}>
               {words}
            </Typography>
            <br/>
         </React.Fragment>
      );
   });

   
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h4" style={{marginBottom:10}}>
               {genzMode ? 'Gen Z: ' : 'KJV: '}{getNameFromAbbreviation(book.abbrev)} {chapterNumber}
               {genzMode && chosenAudioChapter && <PlayAudioChapter title={`${getNameFromAbbreviation(book.abbrev)} ${chapterNumber}`} src={chosenAudioChapter}/>}
            </Typography>
            <div>{verses}</div>
         </CardContent>
      </Card>
   );
};

const PageLayout = () => {
   const { state: {matchSM} } = useContext(SearchContext);
   return (<>
      <Grid container item xs={12} spacing={matchSM ? 0 : 2} justifyContent={"space-between"}>
         
         <Grid item xs={matchSM ? 12 : 6} style={{ order: matchSM ? 1 : 1 }}><ChapterContent genzMode={true} /></Grid>
         <Grid item xs={matchSM ? 12 : 6} style={{ order: matchSM ? 2 : 2 }}><ChapterContent genzMode={false} /></Grid>
         <Grid item xs={matchSM ? 12 : 6} style={{ order: matchSM ? 3 : 3 }}>
            <SidebarWho/>
            <SidebarWhat/>
            <SidebarWhy/>
            <SidebarWhen/>
            <EventsByLocation/>
         </Grid>
       {/*  <Grid item xs={matchSM ? 12 : 6} style={{ order: 3 }}>
         <ModernCommentary/>
         </Grid>*/}
         <Grid item xs={matchSM ? 12 : 6} style={{ order: 4 }}>
            <SidebarFacts/>
            <SidebarMytho/>
            <Commentary/>
         </Grid>
      </Grid>
   </>
   );
};
const SidebarWho = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Who } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Who
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ Who }</div>
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarWhat = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { What } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               What
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ What }</div>
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarWhy = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Why } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Why
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ Why }</div>
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarWhen = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { When } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               When
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ When }</div>
            </>}
         </CardContent>
      </Card>
   );
};

const SidebarFacts = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Fun_Facts } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Fun Facts
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               { Boolean(Fun_Facts) && Fun_Facts.map(fact=><ListItem>{fact}</ListItem>) }
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarMytho = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Unofficial_Mythology } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Unofficial Mythology
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               { Boolean(Unofficial_Mythology) && Unofficial_Mythology.map(fact=><ListItem>{fact}</ListItem>) }
            </>}
         </CardContent>
      </Card>
   );
};
const CommunityCTA = () => {
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Community
            </Typography>
            <a target={'_blank'}
               rel="noopener noreferrer" href={'https://discord.gg/thecongregation'}>
               <img alt="Join The Congregation"
                    src={'https://invidget.switchblade.xyz/thecongregation?theme=light'} style={{ maxWidth: '100%', margin:'auto', display:'block' }} />
            </a>
         </CardContent>
      </Card>
   );
};
const HomePage = () => {
   const { state: {matchSM} } = useContext(SearchContext);
   return (<>
         {matchSM && <Header/>}
      <Box style={{marginTop: matchSM ? -20 : 0, marginLeft: matchSM ? 0 :40, marginRight: matchSM ? -15 : 0}}>
         <SearchFormBox/>
         <Grid container item xs={12} spacing={2}>
         <Grid item xs={matchSM ? 12 : 6}>
            <WordCloud/>
            <BibleEventsByLocation/>
         </Grid>
         <Grid item xs={matchSM ? 12 : 6}>
            <BibleEventList/>
            <BibleCharacterNetworkGraph/>
         </Grid>
         <Grid item xs={12}>
            <BibleVerseCountChart/>
         </Grid>
         <Grid item xs={12}>
            <CommunityCTA/>
         </Grid>
         </Grid>
      </Box>
      </>
   );
};

const Header = () =>{
   const { state: {matchSM}, actions: {toggleMenu} } = useContext(SearchContext);
   return (<Card style={{height:80, marginBottom:20, marginLeft: matchSM ? 0 : 40, marginRight: matchSM ? 0 : 15}}>
      <Grid container item xs={12} justifyContent={"space-between"}>
         <Grid item xs={4}>
            {matchSM && <IconButton onClick={toggleMenu} style={{marginTop: 17}}>
               <Menu/>
            </IconButton>}
         </Grid>
         <Grid item xs={4} style={{textAlign:'center',margin:'auto'}}>
            <a href={'../../'} style={{display:'block',margin:'0px auto auto',width:100}}>
               <img src={Logo} style={{margin:'5px auto auto',height:65}}/>
            </a>
         </Grid>
         <Grid item xs={4}>
            &nbsp;
         </Grid>
      </Grid>
   </Card>)
}

export default RenderBook;









