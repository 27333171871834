import React, {useContext} from 'react'
import {Card, CardContent, CircularProgress, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {SearchContext, useData} from "../../lib/contexts";


export default function Commentary() {
   const {
      state: {bibleGPTCommentaryLoading, bibleGPTCommentaryData, matchSM}
   } = useContext(SearchContext);
   const { memoizedTopWords } = useData() ?? {};
   
   const { Commentary } = bibleGPTCommentaryData || {};
   const topWordSet = new Set(memoizedTopWords.map((wordObj) => wordObj.word.toLowerCase()));
   
   const processItem = (item) => {
      const words = item.split(' ').map((word, wordIndex) => {
         const cleanWord = word.replace(/[.,;:'"?!()]/g, '').toLowerCase(); // remove punctuation and convert to lowercase
         if (topWordSet.has(cleanWord)) {
            return (
               <React.Fragment key={wordIndex}>
                  <a href={`../../?q=${cleanWord}`}>{word}</a>{' '}
               </React.Fragment>
            );
         }
         return word + ' ';
      });
      return words;
   };

   
   return (
      <Card style={{marginTop:20, height: matchSM ? 300 : 400}}>
         <CardContent>
            <Typography variant="h5">
               Commentary
            </Typography>
            {bibleGPTCommentaryLoading ? (
               <CircularProgress style={{display:'block',margin:'130px auto auto'}}/>
            ) : (
               <List style={{height: matchSM ? 250 : 350, overflowY: "auto"}}>
                  {Boolean(Commentary) && Commentary.map((item, index) => (
                     <ListItem key={index}>
                        <ListItemText primary={processItem(item)} />
                     </ListItem>
                  ))}
               </List>
            )}
         </CardContent>
      </Card>
   );
}
