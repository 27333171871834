import React, {useContext, useMemo} from 'react';
import {
   Card,
   CardContent,
   Divider,
   Drawer, Grid,
   makeStyles,
   Typography,
   List,
   FormHelperText,
   ListItem,
   ListItemText, CircularProgress
} from '@material-ui/core';
import {
   Skeleton
} from '@material-ui/lab';

import {SearchContext, useData} from "../../lib/contexts";
import {getNameFromAbbreviation, prettyNumber} from "../../lib/constants/helpers";
import BibleVerseCountChartNoCard from "../BookCharts/BibleVerseCountChartNoCard";

const useStyles = makeStyles({
   mobile:{
      width:'100% !important',
      minWidth:'100% !important'
   }
});
const SearchDrawer = () => {
   const classes = useStyles();
   const {
      state: {  bibleGPTWordCommentaryLoading, bibleGPTWordCommentaryData, matchSM, searchOpen, debounceQuery},
      actions: { toggleSearch},
   } = useContext(SearchContext);
   
   const { memoizedTopWords, memoizedKjvBible, memoizedTopWordsEtymology } = useData() ?? {};
   
   const { Hebrew_Etymology = [], Greek_Etymology = [], Commentary = [] } = debounceQuery ? bibleGPTWordCommentaryData ?? {} : {};
   const totalUsageCount = useMemo(() => memoizedTopWords.filter((item) => item.word.toLowerCase() === debounceQuery?.toLowerCase())[0]?.count || 0, [memoizedTopWords,debounceQuery]);
   const relatedTerms = useMemo(() => memoizedTopWords.filter((item) => item.word.toLowerCase() === debounceQuery?.toLowerCase())[0]?.links || [], [memoizedTopWords,debounceQuery]);
   const relatedTermsEtymology = useMemo(() => {
      // Make sure relatedTerms is an array and map it to lowercase
      const relatedTermsLower = Array.isArray(relatedTerms) ? relatedTerms.map(term => term.toLowerCase()) : [];
      
      // Filter out etymology data for related terms
      return memoizedTopWordsEtymology.filter(item => relatedTermsLower.includes(item.word.toLowerCase()));
   }, [memoizedTopWordsEtymology, relatedTerms]);
   
   const totalUsageList = useMemo(() => {
      const usageList = [];
      memoizedKjvBible.forEach((book) => {
         book.chapters.forEach((chapter, chapterIndex) => {
            chapter.forEach((verse, verseIndex) => {
               const cleanVerse = verse.replace(/[.,;:'"?!()]/g, '').toLowerCase(); // remove punctuation and convert to lowercase
               if (cleanVerse.includes(debounceQuery?.toLowerCase())) {
                  usageList.push({
                     book: getNameFromAbbreviation(book.abbrev),
                     chapter: chapterIndex + 1,
                     verse: verseIndex + 1,
                     text: verse
                  });
               }
            });
         });
      });
      return usageList;
   }, [debounceQuery]);
   
   return(
      <Drawer variant={"temporary"} anchor="bottom" open={searchOpen} onClose={toggleSearch}>
         <Card>
            <CardContent style={{maxHeight: matchSM ? 800 : 620, minHeight: matchSM ? 400 : 320, overflowY:'auto', paddingBottom:10}}>
               <Typography variant={matchSM ? "h4" : "h3"} component="h2" gutterBottom>
                  Searching: "{debounceQuery}"
               </Typography>
               <Divider style={{marginBottom: 20, marginTop: 20}}/>
               <Grid container item xs={12} spacing={2}>
                  <Grid item xs={matchSM ? 12 : 4}>
                     <Typography gutterBottom variant={'h5'}>
                        Total Usage Count: {prettyNumber(totalUsageCount)}
                     </Typography>
                     <BibleVerseCountChartNoCard/>
                  </Grid>
                  <Grid item xs={matchSM ? 12 : 4}>
                     <Typography gutterBottom variant={'h5'}>
                        Found in Verses:
                     </Typography>
                     <div>
                        <List style={{overflowY:'auto',maxHeight: matchSM ? 500 : 480, minHeight: matchSM ? 300 : 250,paddingBottom:10}}>
                           {totalUsageList.map((usage, index) => (
                              <ListItem key={index}>
                                 <ListItemText
                                    primary={
                                       <a href={`../${usage.book}/${usage.chapter}`}>
                                          {`${usage.book} ${usage.chapter}:${usage.verse}`}
                                       </a>
                                    }
                                    secondary={usage.text}
                                 />
                              </ListItem>
                           ))}
                        </List>
                     </div>
                  </Grid>
                  <Grid item xs={matchSM ? 12 : 4}>
                     <Typography gutterBottom variant={'h5'}>
                        Hebrew Etymology
                     </Typography>
                     <div>
                        {bibleGPTWordCommentaryLoading ? (
                           <><CircularProgress style={{display:'block',margin:'20px auto auto'}}/>
                           </>
                        ) : (
                           <>
                              {Array.isArray(Hebrew_Etymology) && Hebrew_Etymology?.map((item, index) => (
                                 <ListItem key={index}>
                                    {item}
                                 </ListItem>
                              ))}
                           </>
                        )}
                     </div>
                     <Typography gutterBottom variant={'h5'}>
                        Greek Etymology
                     </Typography>
                     <div>
                        {bibleGPTWordCommentaryLoading ? (
                           <><CircularProgress style={{display:'block',margin:'20px auto auto'}}/>
                           </>
                        ) : (
                           <>
                              {Array.isArray(Greek_Etymology) && Greek_Etymology?.map((item, index) => (
                                 <ListItem key={index}>
                                    {item}
                                 </ListItem>
                              ))}
                           </>
                        )}
                     </div>
                     <Typography gutterBottom variant={'h5'}>
                        Commentary
                     </Typography>
                     <div>
                        {bibleGPTWordCommentaryLoading ? (
                           <><CircularProgress style={{display:'block',margin:'20px auto auto'}}/>
                           </>
                        ) : (
                           <>
                              {Array.isArray(Commentary) && Commentary?.map((item, index) => (
                                 <ListItem key={index}>
                                    {item}
                                 </ListItem>
                              ))}
                           </>
                        )}
                     </div>
                  </Grid>
               </Grid>
               {
                  Boolean(relatedTermsEtymology) &&<>
                     <Typography gutterBottom variant={'h4'}>
                        Terms Related to: "{debounceQuery}"
                     </Typography>
                     <Grid container item xs={12} spacing={2}>
                        {relatedTermsEtymology.map((term, index) => (
                           <Grid key={index} item xs={matchSM ? 12 : 4}>
                              <Typography gutterBottom variant={'h4'}>
                                 "{term.word}"
                              </Typography>
                              <FormHelperText>
                                 Total Usage Count: {term.count}
                              </FormHelperText>
                              <Divider style={{marginBottom: 10, marginTop: 10}}/>
                              <Typography gutterBottom variant={'h5'}>
                                 Hebrew Etymology
                              </Typography>
                              <div>
                                 {bibleGPTWordCommentaryLoading ? (
                                    <><CircularProgress style={{display: 'block', margin: '20px auto auto'}}/>
                                    </>
                                 ) : (
                                    <>
                                       {[term?.hebrew].map((item, index) => (
                                          <ListItem key={index}>
                                             {item}
                                          </ListItem>
                                       ))}
                                    </>
                                 )}
                              </div>
                              <Typography gutterBottom variant={'h5'}>
                                 Greek Etymology
                              </Typography>
                              <div>
                                 {bibleGPTWordCommentaryLoading ? (
                                    <><CircularProgress style={{display: 'block', margin: '20px auto auto'}}/>
                                    </>
                                 ) : (
                                    <>
                                       {[term?.greek].map((item, index) => (
                                          <ListItem key={index}>
                                             {item}
                                          </ListItem>
                                       ))}
                                    </>
                                 )}
                              </div>
                              <Typography gutterBottom variant={'h5'}>
                                 Commentary
                              </Typography>
                              <div>
                                 {bibleGPTWordCommentaryLoading ? (
                                    <><CircularProgress style={{display: 'block', margin: '20px auto auto'}}/>
                                    </>
                                 ) : (
                                    <>
                                       {[term?.commentary].map((item, index) => (
                                          <ListItem key={index}>
                                             {item}
                                          </ListItem>
                                       ))}
                                    </>
                                 )}
                              </div>
                           </Grid>
                        ))
                        }
                     </Grid>
                  </>
               }
            </CardContent>
         </Card>
      </Drawer>
)
};

export default SearchDrawer;
