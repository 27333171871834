import React, { useState, useEffect, useRef } from 'react';
import { Fab, Tooltip } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   fab: {
      borderWidth: '4px',
      borderStyle: 'solid',
      animation: '$pulsate 1s infinite',
   },
   '@keyframes pulsate': {
      '0%': {
         transform: 'scale(1)',
      },
      '50%': {
         transform: 'scale(1.05)',
      },
      '100%': {
         transform: 'scale(1)',
      },
   },
}));

const hexToRGB = hex => {
   const r = parseInt(hex?.slice(1, 3), 16);
   const g = parseInt(hex?.slice(3, 5), 16);
   const b = parseInt(hex?.slice(5, 7), 16);
   return [r, g, b];
};

const interpolateColor = (color1, color2, factor) => {
   const [r1, g1, b1] = color1;
   const [r2, g2, b2] = color2;
   
   const r = Math.round(r1 + (r2 - r1) * factor);
   const g = Math.round(g1 + (g2 - g1) * factor);
   const b = Math.round(b1 + (b2 - b1) * factor);
   
   return `rgb(${r},${g},${b})`;
};

const generateColor = (progress) => {
   const colors = [
      '#e8892c',
      '#a15c28',
      '#561423',
      '#561423',
      '#502f4a',
      '#4e305e',
      '#465970',
      '#3c7f9b',
      '#677e81',
      '#757c72'
   ];
   if (progress === undefined || progress < 0 || progress > 100) {
      return colors[0];
   }
   
   const numberOfColors = colors.length;
   const step = 100 / (numberOfColors - 1);
   
   const index = Math.floor(progress / step);
   const nextIndex = Math.min(index + 1, numberOfColors - 1);
   
   const color1 = hexToRGB(colors[index]);
   const color2 = hexToRGB(colors[nextIndex]);
   
   const factor = (progress - index * step) / step;
   
   return interpolateColor(color1, color2, factor);
};

const PlayAudioChapter = ({ src, title }) => {
   const [isPlaying, setIsPlaying] = useState(false);
   const [progress, setProgress] = useState(0);
   const audioRef = useRef(null);
   const classes = useStyles();
   
   const togglePlayPause = () => {
      const audio = audioRef.current;
      if (isPlaying) {
         audio.pause();
      } else {
         audio.play();
      }
      setIsPlaying(!isPlaying);
   };
   
   useEffect(() => {
      const audio = audioRef.current;
      const updateProgress = () => {
         setProgress((audio.currentTime / audio.duration) * 100);
      };
      const handleAudioEnd = () => {
         // Stop audio if the route changes
         audio.pause();
         setIsPlaying(false);
         setProgress(0);
      };
      audio.addEventListener('timeupdate', updateProgress);
      audio.addEventListener('ended', handleAudioEnd);
      return () => {
         audio.removeEventListener('timeupdate', updateProgress);
         audio.removeEventListener('ended', handleAudioEnd);
      };
   }, []);
   
   // Reset state when src or title changes
   useEffect(() => {
      const audio = audioRef.current;
      audio.pause();
      setIsPlaying(false);
      setProgress(0);
      audio.load();
   }, [src, title]);
   
   const borderColor = generateColor(progress);
   
   return (
      <div style={{marginRight:5,float:'right',display:'inline-block'}}>
         <audio ref={audioRef} src={src}></audio>
         <Tooltip title={`Play ${title} Audio Chapter`}>
         <Fab
            className={isPlaying ? classes.fab : ''}
            size={'small'}
            onClick={togglePlayPause}
            style={{
               borderColor,
               borderWidth: '6px',
               borderStyle: 'solid',
            }}
         >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
         </Fab>
         </Tooltip>
      </div>
   );
};

export default PlayAudioChapter;
