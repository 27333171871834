import React, {useCallback, useContext, useState} from 'react';
import {
   Card, CardContent, Typography,
   Button,
   Grid,
   LinearProgress,
   TextField, Tab, makeStyles, Box, InputAdornment, IconButton, useTheme, useMediaQuery, Select, MenuItem, Avatar
} from '@material-ui/core';
import { TabContext, TabList} from "@material-ui/lab";
import {SearchContext} from "../../lib/contexts";
import SearchResults from "../SearchResults";
import {
   Business, Close,
   EventSeat,
   LibraryBooks,
   OndemandVideo,
   Place, Public, QuestionAnswer, Search,
   Settings,
   ShoppingBasket
} from "@material-ui/icons";
import SettingsMenu from "../SettingsMenu";
import all_countries from "../../lib/constants/countries";

const useStyles = makeStyles(theme => ({
   tabPanel:{
      margin:'25px 0px 0px',
      padding:0,
      width: '100%'
   },
   loading:{width:'100%',margin:50, color:'inherit'},
   icon:{
      display:'inline',
      '& svg':{
         marginBottom:-5,
         marginRight:5
      }
   }
}));
const SearchFormBox = () => {
   const classes = useStyles();
   const theme = useTheme();
   const {
      state: { matchSM, query, resultsLoading, type, showExtendedCategories, companyCountryPhone },
      actions: { setQuery, setType, forceSearch, setCompanyCountryPhone },
   } = useContext(SearchContext);
   
   const [menuAnchor, setMenuAnchor] = useState(null);
   
   const toggleMenu = useCallback((event) => {
      setMenuAnchor(menuAnchor ? null : event.currentTarget);
   },[menuAnchor]);

   const a11yProps = (index, active=true) => {
      return {
         value: String(index),
         id: `scrollable-tab-${index}`,
         'aria-controls': `tab-${index}`,
      };
   }
   const handleTabChange = useCallback((event, value)=>{
      setType(value);
   },[setType])
   
   return (
      <Card style={{marginBottom:10}}>
         <CardContent>
            <Box style={{height: 60}}>
               <TextField
                  autoFocus={true}
                  fullWidth
                  value={query}
                  label={`Search...`}
                  margin="normal"
                  variant="outlined"
                  onChange={event=>setQuery(event.target.value)}
                  InputProps={{
                     endAdornment: <InputAdornment position="end">
                        {query && <IconButton
                           aria-label="clear"
                           onClick={()=>setQuery('')}
                        >
                           <Close/>
                        </IconButton>}
                        <IconButton
                           aria-label="search"
                           onClick={forceSearch}
                        >
                           <Search />
                        </IconButton>
                     </InputAdornment>}}
               
               />
            </Box>
         </CardContent>
      </Card>
   );
};

export default SearchFormBox;
