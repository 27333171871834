import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getBibleGPTWordCommentary } from "../api/getBibleGPTWordCommentary";
import topWordsEtymology from '../../assets/en_kjv_top_words_etymology.json';

const useBibleGPTWordCommentary = (term) => {
  const localData = useMemo(() => {
    return term
       ? topWordsEtymology.top_words_etymology.find(wordInfo => wordInfo.word.toLowerCase() === term.toLowerCase())
       : null;
  }, [term]);
  const result = useQuery('biblegpt_word_commentary_' + term, async () => {
    if(!term){
      return {
        Hebrew_Etymology: [],
        Greek_Etymology: [],
        Commentary: []
      };
    }
    if (localData) {
      //console.log('found term in constants: ', JSON.stringify(localData))
      return {
        Hebrew_Etymology: [localData.hebrew],
        Greek_Etymology: [localData.greek],
        Commentary: [localData.commentary]
      };
    }
    return getBibleGPTWordCommentary(term);
  }, {
    refetchOnWindowFocus: false,
  });
  
  return result;
};


export default useBibleGPTWordCommentary;
